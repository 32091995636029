.nosotros {
    font-size: 24px;
    line-height: 32px;
    background: var(--gris-claro);
    opacity: 0.4;
    color: #ffffff;
    padding-left: 25%;
    padding-right: 25%;
    font-feature-settings: 'salt' on, 'liga' off;

  }
 