.contact-text {
  color: var(--gris-claro);
}
.form input,
.form textarea {
  border-color: var(--rosa-oscuro);
  border-radius: 3px;
  border-width: 2px;
  margin: 0.4rem;
  width: 700px;
}
.form input {
  width: 700px;
  height: 54px;
}
.form textarea {
  resize: none;
}
.form button {
  background-color: var(--rosa-oscuro);
  color: #ffffff;
  width: 300px;
  height: 50px;
  margin-left: 0.4rem;
}
.form button:hover {
  background-color: #ffffff;
  border-color: var(--rosa-oscuro);
  color: var(--rosa-oscuro);
}

@media only screen and (max-width: 767px) {
  .form input,
  .form textarea {
    width: 100%;
  }

  .form button {
    width: 100%;
  }
}

@media only screen and (max-width: 576px) {
  .form input,
  .form textarea {
    margin: 0.2rem;
  }

  .form button {
    margin: 0.4rem 0.2rem;
  }
}
