/*Razones*/
.row {
    --bs-gutter-x: 0.9rem !important;
}
.razones {
    background: #d9dbe1;
    overflow: hidden;
  }
  
  .razones .cards {
    background-color: #ffffff;
    align-items: center;
    text-align: center;
    height: 150px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-top: 15px;
    padding: 5px;
  }
  
  .razones .cards img {
    max-width: 80px;
    height: auto;
  }
  
  .razones .cards p {
    display: inline-block;
    vertical-align: top;
  }