:root {
  --main-bg-color: brown;
  --rosa: #f2dada;
  --rojo-oscuro: #a35f5f;
  --rosa-oscuro: #dd969c;
  --gris: #474a57;
  --gris-claro: #50595c;
  --gris-medio: #444f5a;
}

html {
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
}
body {
  height: 100%;
  font-family: "Manrope", sans-serif!important;
  background: var(--rosa);
  color: #333;
}